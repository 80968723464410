<template>
  <b-card class="card-congratulation-medal">

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.image_rayo"
            :text="avatarText(userData.name_rayo)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name_rayo }}
              </h4>
              <span class="card-text">{{ userData.email_rayo }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData._id } }"
                variant="primary"
              >
                Editar
              </b-button>

            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="PackageIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userSumary!=undefined? this.$parent.$data.userSumary.total : 0 }}
              </h5>
              <small>Entregas realizadas</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="success"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                USD {{ userSumary!=undefined? this.$parent.$data.userSumary.total *4 : 0 }}
              </h5>
              <small>Monto generado</small>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-avatar
              variant="secondary"
              rounded
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userSumary!=undefined? this.$parent.$data.userSumary.total === 0 ? 0 : ((this.$parent.$data.userSumary.exitosos / this.$parent.$data.userSumary.total)*100).toFixed(2) :0 }} %
              </h5>
              <small>% de entregas exitosas</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nombre</span>
            </th>
            <td class="pb-50">
              {{ userData.name_rayo }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status_rayo==1 ? "Online" : "Offline" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Rayo ID</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData._id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">EPA</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.provider_rayo }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nacionalidad</span>
            </th>
            <td class="pb-50">
              {{ userData.nationality_rayo }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Fono</span>
            </th>
            <td>
              {{ userData.number_rayo }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BImg,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BImg,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    userSumary: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
